import { DiamondResize } from "@logicflow/extension";

class ResizableDiamondModel extends DiamondResize.model {
  initNodeData(data) {
    super.initNodeData(data);
    this.text.draggable = true;
  }
  getNodeStyle() {
    const style = super.getNodeStyle();
    //style.fill = "skyblue";
    style.strokeWidth = 1;
     style.stroke = "blue";
    
    return style;
  }
}
class ResizableDiamondView extends DiamondResize.view {}


export default {
  type: "resizable-diamond",
  view: ResizableDiamondView,
  model: ResizableDiamondModel
};
