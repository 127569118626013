<template>
  <div class="container" ref="container">
    <div class="button-wrapper">
      <button id="js_download-img">下载快照</button>
      <button id="js_download-xml">下载xml</button>
      <button id="js_getBase64">获取base64</button>
    </div>

    <el-drawer  :append-to-body="true" :modal="false"  title="配置面板" :visible.sync="drawer" :direction="direction"
      :before-close="handleClose" width="30%">
      <div style="padding:20px">
        <el-tabs v-model="activeName">
          <el-tab-pane label="基础信息" name="first">
            <el-form :label-position="labelPosition" label-width="80px" :model="nodeData">
              <el-form-item label="节点id">
                <el-input style="width:100%" size="mini" :value="nodeData.id"> </el-input>
              </el-form-item>
              <el-form-item label="节点名称">
                <el-input effect="dark" type="" :disabled="nodeData.text.value=='流程开始' || nodeData.text.value=='流程结束'" :v-if="nodeData.text.value == ''" v-model="nodeData.text.value"
                  @change="edgeNameBlur(nodeData.text.value)" size="medium"></el-input>
              </el-form-item>
              <el-form-item label="起始节点">

                <el-tag style="margin-right:10px" v-for="tag in sourceTexts" type="info" :key="tag"> {{ tag }} </el-tag>

              </el-form-item>
              <el-form-item label="目标节点">
                <el-tag style="margin-right:10px" v-for="tag in targetTexts" type="success" :key="tag">{{ tag }}</el-tag>
              </el-form-item>
              <el-form-item label="绑定表单" :hidden="nodeData.text.value!='流程开始'" >
                <el-select v-model="formValue" placeholder="请选择"  @change="formChange">
                  <el-option v-for="item in formOptions" :key="item.key" :label="item.value" :value="item.key">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>

          </el-tab-pane>
          <el-tab-pane :disabled="disabled" label="配置" name="second">配置</el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
    

  </div>
</template>

<script>
//环境
//npm install @logicflow/core
//npm install @logicflow/extension

import LogicFlow from "@logicflow/core";
import { Control, DndPanel, SelectionSelect, MiniMap, Snapshot } from '@logicflow/extension';
import { Menu } from '@logicflow/extension';
import "@logicflow/core/dist/style/index.css";
import '@/util/logic-flow/css/index.css'

LogicFlow.use(Menu);

import ResizableRect from "@/util/logic-flow/js/ResizableRect.js";
import ServiceRect from "@/util/logic-flow/js/ServiceRect.js";
import ResizableEllipse from "@/util/logic-flow/js/ResizableEllipse.js";
import ResizableHexagon from "@/util/logic-flow/js/ResizableHexagon.js";
import ResizableDiamond from "@/util/logic-flow/js/ResizableDiamond.js";
import ResizableHtml from "@/util/logic-flow/js/ResizableHtml.js";
import UserTask from "@/util/logic-flow/js/UserTask.js";
import CustomEdge from "@/util/logic-flow/js/CustomEdge.js"
import CustomCircle from "@/util/logic-flow/js/CustomCircle.js"


import requestApi from "@/api/flow.js";


export default {

  data() {
    return {
      activeName: 'first',
      drawer: false,
      direction: 'rtl',
      graphData: {},
      nodeData: {
        "id": "",
        "type": "",
        "x": 0,
        "y": 0,
        "properties": {},
        "text": {
          "x": 0,
          "y": 0,
          "value": ""
        }
      },
      sourceTexts: [], //起始
      targetTexts: [], //目标
      labelPosition: "left",
      elementId: "",
      disabled: true,
      formOptions: [],
      formValue: "",
      dialogVisible : false
    };
  },
  components:{
    
  },
  mounted() {
    this.init()
    
  },
  created(){
    this.initFormSelect();
  },
  methods: {
    handleClose(done) {
      done();
    },
    init() {
      this.lf = new LogicFlow({
        container: this.$refs.container,
        //网格
        grid: true,
        //快捷键配置
        keyboard: {
          enabled: true,
          shortcuts: [
            {
              //绑定事件
              keys: ["backspace", "delete"],
              callback: (key) => {
                const elements = this.lf.getSelectElements(true);
                this.lf.clearSelectElements();
                elements.edges.forEach((edge) => this.lf.deleteEdge(edge.id));
                elements.nodes.forEach((node) => this.lf.deleteNode(node.id));
              },
            }
          ]
        },
        //组测组件 1、拖拽面板 2、框选  3、控制面板 4、小地图  5、右键菜单
        plugins: [DndPanel, SelectionSelect, Control, MiniMap, Snapshot, Menu],
        edgeGenerator: (sourceNode, targetNode, currentEdge) => {
          if (sourceNode.type === "circle") {
            return "custom-edge";
          }
        }
      });
      this.lf.register(ResizableRect);
      this.lf.register(ServiceRect);
      this.lf.register(ResizableEllipse);
      this.lf.register(ResizableHexagon);
      this.lf.register(ResizableDiamond);
      this.lf.register(ResizableHtml);
      this.lf.register(UserTask);
      this.lf.register(CustomEdge);
      // this.lf.setDefaultEdgeType("custom-edge");
      this.lf.register(CustomCircle);


      //导航
      this.lf.extension.control.addItem({
        iconClass: "custom-minimap",
        title: "",
        text: "导航",
        onClick: (lf, ev) => {
          const position = lf.getPointByClient(ev.x, ev.y);
          lf.extension.miniMap.show(
            position.domOverlayPosition.x - 120,
            position.domOverlayPosition.y + 35
          );
        }
      });
      //下载
      this.lf.extension.control.addItem({
        iconClass: "bpmn-upload",
        title: "",
        text: "下载",
        onClick: (lf, ev) => {
          lf.getSnapshot();
        }
      });
      //保存
      this.lf.extension.control.addItem({
        iconClass: "bpmn-save",
        title: "",
        text: "保存",
        onClick: (lf, ev) => {
          this.graphData = lf.getGraphData()
          console.log(JSON.stringify(this.graphData))
        }
      });

      this.lf.extension.dndPanel.setPatternItems([
        {
          label: "选区",
          className: "custom-selection",
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAApxJREFUWEftmM1u00AQx2eMJbjhOtwBKY7EifIG8ASUJ6Ac4xxo3sB5gqSH2kfCE8AjlCcgN6Q4UsOduO6tElYGzeaja9exvXI2oVL24jhez/zyn8zO7qDth9cAYMFixJHrHC0/a7mo+kPbD0kmiVwH5XvbD/sEcCx9FxMm3bj9airPs4JfL5DMvvRjAQFGket0M/YK/WVVKQcMJgMkWgPOEWKC5CwXEMyBQetoACGOonbzrC5gDABPl0ZuItdZhVtXiJX8pcKphaim0QNgTQE50f7vIQDtYDLgazbjdKGr+BOADT+85OvMdd7qgpLtqvg7AOZFRFnBoyD8zoau287JLkKs4k+EmOuo2Clk6qsuWBV/D2OZ0aXUNuweFKyr4sNIEpW0r6sIv6/i71BJtlJJVEqPaoiti/Fx3GmN5PeeBaHYlPxpO2KTUjS0b7cWp0aaIhjezG1+LQPKPte+Dtr+eAiAH9kxAVw+QuhVUW4Fqh2QQ2wg/pSVYVDC5FOV2o9sABAXx06im+z/RTUkefNtP+T/4Ot7zxCHc/jbKwItPbhvA7BxMT4lxC8bbMWAMJjfmudx9yWfmVOjFDDTSxEvz4neZJW+F0qCXtRxvJU32w/lA3seawxEXtRpncsPSwHZsWlgqtuQ3Jqj7K+1+leW+SRZt0gSSKZy6MRBiehzeUTSGY/84qr3ktdLKTdYbQZvUg0yr6rNvst47VksA3ENRoL3VSER4MdOARv+5ISAvlUA/I1E3qzTGu4UkMFsP+S+4vM8SKEYgicv5PsA5H4hNzrTywngh5nbFKfLVBZXkHurUzjbjccJt53Xg5Xb1NXYuYKLMN/VZ743EN5tqs97AZQX9SL1GH4vgMtkEfW5SL29AnJ9BsTTso7aP9GfT0LnGEEPAAAAAElFTkSuQmCC",
          callback: () => {
            this.lf.openSelectionSelect();
            this.lf.once("selection:selected", () => {
              this.lf.closeSelectionSelect();
            });
          }
        },
        {
          id: '',
          type: "custom-cirlce",
          text: "流程开始",
          label: "流程开始",
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAAH6ji2bAAAABGdBTUEAALGPC/xhBQAAAnBJREFUOBGdVL1rU1EcPfdGBddmaZLiEhdx1MHZQXApraCzQ7GKLgoRBxMfcRELuihWKcXFRcEWF8HBf0DdDCKYRZpnl7p0svLe9Zzbd29eQhTbC8nv+9zf130AT63jvooOGS8Vf9Nt5zxba7sXQwODfkWpkbjTQfCGUd9gIp3uuPP8bZ946g56dYQvnBg+b1HB8VIQmMFrazKcKSvFW2dQTxJnJdQ77urmXWOMBCmXM2Rke4S7UAW+/8ywwFoewmBps2tu7mbTdp8VMOkIRAkKfrVawalJTtIliclFbaOBqa0M2xImHeVIfd/nKAfVq/LGnPss5Kh00VEdSzfwnBXPUpmykNss4lUI9C1ga+8PNrBD5YeqRY2Zz8PhjooIbfJXjowvQJBqkmEkVnktWhwu2SM7SMx7Cj0N9IC0oQXRo8xwAGzQms+xrB/nNSUWVveI48ayrFGyC2+E2C+aWrZHXvOuz+CiV6iycWe1Rd1Q6+QUG07nb5SbPrL4426d+9E1axKjY3AoRrlEeSQo2Eu0T6BWAAr6COhTcWjRaYfKG5csnvytvUr/WY4rrPMB53Uo7jZRjXaG6/CFfNMaXEu75nG47X+oepU7PKJvvzGDY1YLSKHJrK7vFUwXKkaxwhCW3u+sDFMVrIju54RYYbFKpALZAo7sB6wcKyyrd+aBMryMT2gPyD6GsQoRFkGHr14TthZni9ck0z+Pnmee460mHXbRAypKNy3nuMdrWgVKj8YVV8E7PSzp1BZ9SJnJAsXdryw/h5ctboUVi4AFiCd+lQaYMw5z3LGTBKjLQOeUF35k89f58Vv/tGh+l+PE/wG0rgfIUbZK5AAAAABJRU5ErkJggg=="
        },
        {
          id: '',
          type: "user-task",
          label: "用户任务",
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAEFVwZaAAAABGdBTUEAALGPC/xhBQAAAqlJREFUOBF9VM9rE0EUfrMJNUKLihGbpLGtaCOIR8VjQMGDePCgCCIiCNqzCAp2MyYUCXhUtF5E0D+g1t48qAd7CCLqQUQKEWkStcEfVGlLdp/fm3aW2QQdyLzf33zz5m2IsAZ9XhDpyaaIZkTS4ASzK41TFao88GuJ3hsr2pAbipHxuSYyKRugagICGANkfFnNh3HeE2N0b3nN2cgnpcictw5veJIzxmDamSlxxQZicq/mflxhbaH8BLRbuRwNtZp0JAhoplVRUdzmCe/vO27wFuuA3S5qXruGdboy5/PRGFsbFGKo/haRtQHIrM83bVeTrOgNhZReWaYGnE4aUQgTJNvijJFF4jQ8BxJE5xfKatZWmZcTQ+BVgh7s8SgPlCkcec4mGTmieTP4xd7PcpIEg1TX6gdeLW8rTVMVLVvb7ctXoH0Cydl2QOPJBG21STE5OsnbweVYzAnD3A7PVILuY0yiiyDwSm2g441r6rMSgp6iK42yqroI2QoXeJVeA+YeZSa47gZdXaZWQKTrG93rukk/l2Al6Kzh5AZEl7dDQy+JjgFahQjRopSxPbrbvK7GRe9ePWBo1wcU7sYrFZtavXALwGw/7Dnc50urrHJuTPSoO2IMV3gUQGNg87IbSOIY9BpiT9HV7FCZ94nPXb3MSnwHn/FFFE1vG6DTby+r31KAkUktB3Qf6ikUPWxW1BkXSPQeMHHiW0+HAd2GelJsZz1OJegCxqzl+CLVHa/IibuHeJ1HAKzhuDR+ymNaRFM+4jU6UWKXorRmbyqkq/D76FffevwdCp+jN3UAN/C9JRVTDuOxC/oh+EdMnqIOrlYteKSfadVRGLJFJPSB/ti/6K8f0CNymg/iH2gO/f0DwE0yjAFO6l8JaR5j0VPwPwfaYHqOqrCI319WzwhwzNW/aQAAAABJRU5ErkJggg==",
          className: "important-node",
          properties: {
            nodeSize: {
              width: 130,
              height: 70
            }
          }
        },
        // {
        //   type: "service-rect",
        //   label: "系统任务",
        //   icon:
        //     "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAAAXNSR0IArs4c6QAAAm9JREFUSEu1lTFo1GAUx38vZ6sOooIoVsWKk23SCp1UkIrg0EHs0M5OOolY9NJz6XXpNaeidOhQB3G1oCLUTXRxK9pe7lykk7YKhdJZuDzJXXMkaXKpBd+Y7//93sv/ve/7hP8YshO2WdKiGPQ0tMpvVTaqBSlm7f0X+EQAU4/JXcNNR5/jsRoATEcnBFqVKhSrtkz6ycySDkqOKTcvF+N/sq1yy9FXwEjDAY9JMRgDDiRYsIJQQHkGdAE11xYzrIvAw+AsP1PW511bRoO1KLysIyh+5ckhfEK5AOxNEig8rNpSSoT7H62yfkQZjGxWCl4nc7Ux2egu6r6D+xnyYBY41q7JkcrPPdXje/6wFga7tqROlOXoMtAX6OPaxkazrNekTpdCtxi0Rg7htpuXuTSXrJIOYLAYrh6DH/5ZqI7LQgNuOboADMUhRgcnl8dktV1zrbIuogzENOuuLUcDeGv8WiKh4ualP2tqrGmdQbiTDk8WbLq2HM6EO/oGuBHTfXFtGWh67t8dOU6jnAKuhoTDri1v0xKcL2l33eArcGhL8wHhFx4/3XEpRCahd1r7DWEpNKdLRidXlu7JZlICq6wvUG4Ga16O3tp9+ZY656aj6wJHQrD3Rge34o21HH0CjashiPbHv/ex9hh1atuqFCpAVet8J8cZUU7E7GtuEUbdvMwnVm45qlkNzFwPJYh4bpZ0WAxehm7BFeBsG2B0vV3ljQM1rZcR5gRmKrbM9jl6HcgrXAr97megXLHlnRVcdjFw06WEMB9pX/WB+D43YuuZS32J/ARhr1OnJTFZ8w29C9SBNfV4vetnLrNpOxT8BWh07BjujplpAAAAAElFTkSuQmCC",
        //   className: "import_icon",
        //   properties: {
        //     nodeSize: {
        //       width: 130,
        //       height: 70
        //     },
        //     disabled: true
        //   }
        // },
        {
          type: "resizable-diamond",
          label: "条件判断",
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAAHeEJUAAAAABGdBTUEAALGPC/xhBQAAAvVJREFUOBGNVEFrE0EU/mY3bQoiFlOkaUJrQUQoWMGePLX24EH0IIoHKQiCV0G8iE1covgLiqA/QTzVm1JPogc9tIJYFaQtlhQxqYjSpunu+L7JvmUTU3AgmTfvffPNN++9WSA1DO182f6xwILzD5btfAoQmwL5KJEwiQyVbSVZ0IgRyV6PTpIJ81E5ZvqfHQR0HUOBHW4L5Et2kQ6Zf7iAOhTFAA8s0pEP7AXO1uAA52SbqGk6h/6J45LaLhO64ByfcUzM39V7ZiAdS2yCePPEIQYvTUHqM/n7dgQNfBKWPjpF4ISk8q3J4nB11qw6X8l+FsF3EhlkEMfrjIer3wJTLwS2aCNcj4DbGxXTw00JmAuO+Ni6bBxVUCvS5d9aa04+so4pHW5jLTywuXAL7jJ+D06sl82Sgl2JuVBQn498zkc2bGKxULHjCnSMadBKYDYYHAtsby1EQ5lNGrQd4Y3v4Zo0XdGEmDno46yCM9Tk+RiJmUYHS/aXHPNTcjxcbTFna000PFJHIVZ5lFRqRpJWk9/+QtlOUYJj9HG5pVFEU7zqIYDVsw2s+AJaD8wTd2umgSCCyUxgGsS1Y6TBwXQQTFuZaHcd8gAGioE90hlsY+wMcs30RduYtxanjMGal8H5dMW67dmT1JFtYUEe8LiQLRsPZ6IIc7A4J5tqco3T0pnv/4u0kyzrYUq7gASuEyI8VXKvB9Odytv6jS/PNaZBln0nioJG/AVQRZvApOdhjj3Jt8QC8Im09SafwdBdvIpztpxWxpeKCC+EsFdS8DCyuCn2munFpL7ctHKp+Xc5cMybeIyMAN33SPL3ZR9QV1XVwLyzHm6Iv0/yeUuUb7PPlZC4D4HZkeu6dpF4v9j9MreGtMbxMMRLIcjJic9yHi7WQ3yVKzZVWUr5UrViJvn1FfUlwe/KYVfYyWRLSGNu16hR01U9IacajXPei0wx/5BqgInvJN+MMNtNme7ReU9SBbgntovn0kKHpFg7UogZvaZiOue/q1SBo9ktHzQAAAAASUVORK5CYII="
        },
        {
          type: "custom-cirlce",
          text: "流程结束",
          label: "流程结束",
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAAH6ji2bAAAABGdBTUEAALGPC/xhBQAAA1BJREFUOBFtVE1IVUEYPXOf+tq40Y3vPcmFIdSjIorWoRG0ERWUgnb5FwVhYQSl72oUoZAboxKNFtWiwKRN0M+jpfSzqJAQclHo001tKkjl3emc8V69igP3znzfnO/M9zcDcKT67azmjYWTwl9Vn7Vumeqzj1DVb6cleQY4oAVnIOPb+mKAGxQmKI5CWNJ2aLPatxWa3aB9K7/fB+/Z0jUF6TmMlFLQqrkECWQzOZxYGjTlOl8eeKaIY5yHnFn486xBustDjWT6dG7pmjHOJd+33t0iitTPkK6tEvjxq4h2MozQ6WFSX/LkDUGfFwfhEZj1Auz/U4pyAi5Sznd7uKzznXeVHlI/Aywmk6j7fsUsEuCGADrWARXXwjxWQsUbIupDHJI7kF5dRktg0eN81IbiZXiTESic50iwS+t1oJgL83jAiBupLDCQqwziaWSoAFSeIR3P5Xv5az00wyIn35QRYTwdSYbz8pH8fxUUAtxnFvYmEmgI0wYXUXcCCSpeEVpXlsRhBnCEATxWylL9+EKCAYhe1NGstUa6356kS9NVvt3DU2fd+Wtbm/+lSbylJqsqkSm9CRhvoJVlvKPvF1RKY/FcPn5j4UfIMLn8D4UYb54BNsilTDXKnF4CfTobA0FpoW/LSp306wkXM+XaOJhZaFkcNM82ASNAWMrhrUbRfmyeI1FvRBTpN06WKxa9BK0o2E4Pd3zfBBEwPsv9sQBnmLVbLEIZ/Xe9LYwJu/Er17W6HYVBc7vmuk0xUQ+pqxdom5Fnp55SiytXLPYoMXNM4u4SNSCFWnrVIzKG3EGyMXo6n/BQOe+bX3FClY4PwydVhthOZ9NnS+ntiLh0fxtlUJHAuGaFoVmttpVMeum0p3WEXbcll94l1wM/gZ0Ccczop77VvN2I7TlsZCsuXf1WHvWEhjO8DPtyOVg2/mvK9QqboEth+7pD6NUQC1HN/TwvydGBARi9MZSzLE4b8Ru3XhX2PBxf8E1er2A6516o0w4sIA+lwURhAON82Kwe2iDAC1Watq4XHaGQ7skLcFOtI5lDxuM2gZe6WFIotPAhbaeYlU4to5cuarF1QrcZ/lwrLaCJl66JBocYZnrNlvm2+MBCTmUymPrYZVbjdlr/BxlMjmNmNI3SAAAAAElFTkSuQmCC"
        },
      ]);
      // 为菜单追加选项（必须在 lf.render() 之前设置）
      this.lf.extension.menu.addMenuConfig({
        nodeMenu: [
          {
            text: '设置',
            callback(node) {
            },
          },
        ]
      });
      document.oncontextmenu = function (e) {
        return false;//阻止浏览器的默认弹窗行为
      }

      let dfd = { "nodes": [{ "id": "6bedc19a-c711-489a-b72a-aa76f9625f0b", "type": "circle", "x": 540, "y": 240, "properties": {}, "text": { "x": 540, "y": 240, "value": "流程开始" } }, { "id": "f1456e2a-baef-4814-940f-a86e9b011abf", "type": "user-task", "x": 780, "y": 240, "properties": { "nodeSize": { "width": 130, "height": 70 } }, "text": { "x": 780, "y": 240, "value": "部门经理审核" } }, { "id": "0c7b45d4-5cb7-4611-9f0e-a5a09f9ae384", "type": "circle", "x": 1040, "y": 240, "properties": {}, "text": { "x": 1040, "y": 240, "value": "流程结束" } }], "edges": [{ "id": "f5fbf10c-0107-4be8-a4d9-aa2003d06be0", "type": "line", "sourceNodeId": "f1456e2a-baef-4814-940f-a86e9b011abf", "targetNodeId": "0c7b45d4-5cb7-4611-9f0e-a5a09f9ae384", "startPoint": { "x": 855, "y": 240 }, "endPoint": { "x": 990, "y": 240 }, "properties": {} }, { "id": "a0fe5d21-6e19-4df4-8dae-a60bb1b09cb9", "type": "line", "sourceNodeId": "6bedc19a-c711-489a-b72a-aa76f9625f0b", "targetNodeId": "f1456e2a-baef-4814-940f-a86e9b011abf", "startPoint": { "x": 590, "y": 240 }, "endPoint": { "x": 705, "y": 240 }, "properties": {}, "pointsList": [{ "x": 590, "y": 240 }, { "x": 705, "y": 240 }] }] }
      this.lf.render(dfd);
      this.logicFlowEvent();
    },
    getNodeName(data, id) {
      let node = data.nodes.filter(f => f.id === id);
      if (node.length > 0) {
        if (node[0].text) {
          return node[0].text.value;
        } else {
          this.$msg_err("请添加节点名称");
        }

      }
      return "";
    },

    edgeNameBlur(value) {
      this.graphData.nodes.forEach(f => {
        if (f.id == this.elementId) {
          f.text.value = value
        }
      })
      this.lf.renderRawData(this.graphData);
    },
    logicFlowEvent() {
      this.lf.on('node:dbclick', ({ data }) => {
        this.elementId = data.id
        this.drawer = true;
        this.nodeData = data;
        console.log(data)
       
        if (data.type == "user-task") {
          this.disabled = false;
        } else {
          this.disabled = false;
        }

        if (!this.nodeData.text) {
          this.nodeData.text = { value: "" }
        }
        this.graphData = this.lf.getGraphData()
        this.targetTexts = [];
        this.sourceTexts = [];
        if (this.graphData.edges) {
          //起始节点
          let sourceNodes = this.graphData.edges.filter(f => f.targetNodeId == data.id);
          //结束节点
          let targetNodes = this.graphData.edges.filter(f => f.sourceNodeId == data.id);
          sourceNodes.forEach(item => {
            this.sourceTexts.push(this.getNodeName(this.graphData, item.sourceNodeId))
          })
          targetNodes.forEach(item => {
            this.targetTexts.push(this.getNodeName(this.graphData, item.targetNodeId))
          })
        }

      })

      this.lf.on('node:add', ({ data }) => {
        //添加 默认数据
        //验证是否有开启流程节点
        let datas = this.lf.getGraphData();

        let text;
        if (data.type == "user-task") {
          text = { "value": "用户任务", x: data.x, y: data.y }
          datas.nodes.forEach(item => {
            if (item.id == data.id) {
              item.text = text;
            }
          })
        }
        else if (data.type == "service-rect") {
          text = { "value": "系统任务", x: data.x, y: data.y }
          datas.nodes.forEach(item => {
            if (item.id == data.id) {
              item.text = text;
            }
          })
        }
        this.lf.renderRawData(datas)
      })
      
     
    },
    formChange(key){
      this.drawer=false
      this.lf.renderRawData(this.graphData);
      let data = this.formOptions.filter(f => f.key == key);
      
      if (data.length > 0) {
        //保存表单数据
        localStorage.setItem("formData", JSON.stringify(data) );
        this.$router.push("form")
      }
    
    },
    initFormSelect(){
      let defaultData = [{ key: 0,value: '新建表单',context:""}];
      this.formOptions = defaultData;
      this.$get(requestApi.formSelectList, {}).then((res) => {
          if (res.success && res.statusCode == 200) {
            if(res.data.length>0){
              res.data.forEach(f=> this.formOptions.push(f))
            } 
          }
        });
    }


  },


};

</script>

<style scoped>
.container {
  width: 100%;
  height: 85vh;
}

.map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.button-wrapper {
  position: absolute;
  right: 0;
  top: 0;
}
</style>