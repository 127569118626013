import { CircleNode, CircleNodeModel } from "@logicflow/core";

class CustomCircleModel extends CircleNodeModel {
  
    initNodeData(data) {
      console.log(data)
        super.initNodeData(data);
        const circleOnlyAsTarget = {
          message: "正方形节点下一个节点只能是圆形节点",
          validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
            return targetNode.type === "user-task";
          },
        };
        this.sourceRules.push(circleOnlyAsTarget);
      }
}
class CustomCircle extends CircleNode {}

export default {
    type: "custom-cirlce",
    model: CustomCircleModel,
    view: CustomCircle
  };
  